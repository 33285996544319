// Core
import { makeAutoObservable } from "mobx";

export type ConsentCookiesStoreType = {
  individualSettings: boolean;
  isOpenConsentCookies: boolean;
  isCookieDetails: boolean;
  setIndividualSettings: (value: boolean) => void;
  setIsOpenConsentCookies: (value: boolean) => void;
  setIsCookieDetails: (value: boolean) => void;
};
const ConsentCookiesStore = () =>
  makeAutoObservable<ConsentCookiesStoreType>(
    {
      individualSettings: false,
      isOpenConsentCookies: false,
      isCookieDetails: false,
      setIndividualSettings(value) {
        this.individualSettings = value;
      },
      setIsOpenConsentCookies(value) {
        this.isOpenConsentCookies = value;
      },
      setIsCookieDetails(value) {
        this.isCookieDetails = value;
      },
    },
    {},
    { autoBind: true },
  );

export default ConsentCookiesStore;
