// Core
import { makeAutoObservable } from "mobx";

// Definitions
import { Nullable } from "@/common/types/basic";

export type ControlType = {
  loading: boolean;
  error: Nullable<Error>;
  pageModals: Record<string, boolean>;
  pageModalsProps: Record<keyof ControlType["pageModals"], unknown>;
  permissions: Record<string, string>;
  toggleLoading: () => void;
  setAppError: (error: Nullable<Error>) => void;
  setOnPageModal: (name: string, visible: boolean, props?: unknown) => void;
  getOnPageModals: () => ControlType["pageModals"];
  getOnPageModalProps: (name: string) => unknown;
  flushModals: () => void;
  permissionGranted: (action: string) => boolean;
};

const ControlStore = () =>
  makeAutoObservable<ControlType>(
    {
      loading: false,
      error: null,
      permissions: {},
      pageModals: {},
      pageModalsProps: {},
      toggleLoading() {
        this.loading = true;
      },

      setAppError(err) {
        this.error = err;
      },

      setOnPageModal(name, visible, props) {
        this.pageModals[name] = visible;
        props && (this.pageModalsProps[name] = props);
      },

      getOnPageModals() {
        return this.pageModals;
      },

      getOnPageModalProps(name) {
        return this.pageModalsProps[name];
      },

      flushModals() {
        this.pageModals = {};
        this.pageModalsProps = {};
      },

      permissionGranted(action) {
        return !!action;
      },
    },
    {},
    { autoBind: true },
  );

export default ControlStore;
