export const verifyBuild = (): {
  isDevelopment: boolean;
  isProduction: boolean;
} => {
  const isDevelopment = process.env.NEXT_PUBLIC_BUILD_ENV === "development";
  const isProduction = process.env.NEXT_PUBLIC_BUILD_ENV === "production";

  return {
    isDevelopment,
    isProduction,
  };
};
